@use "variables" as *;

$main-nav-text-color: var(--jl-punch);

#site-header {
  display: flex;
  justify-content: space-between;
  padding-left: 45px;
  padding-right: 45px;

  & > div {
    margin-left: 10px;
  }


// 	nav.main-nav > ul > li > a {
// 		padding-left: 10px;
// 		padding-right: 10px;
//     }
//     
//     nav.main-nav > ul > li > a:hover {
//     		background-color: rgba(127,127,127,0.25);
// 
//     }
    
  nav {
    text-align: center;
    display: flex;
    justify-content: center;
    margin-top: 36px;
    text-transform: uppercase;
    font-size: 0.875rem;

    a {
      display: block;
      text-decoration: none;
      color: $main-nav-text-color;
      font-weight: 500;
    }

    a.button {
      color: white;
      font-size: 0.75rem;
    }

    a:hover {
      text-decoration: underline;
    }

    .button {
      margin-top: -8px;
      padding: 8px 40px;
    }

    // Transition for text & background colors on hover
    ul li,
    ul li a {
      transition: 0.4s ease-in-out color;
    }

    // All nav lists have NO bullet styling
    ul {
      padding-left: 0;
      list-style: none;
    }
  }

  nav > ul {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
  }

}

#site-header.drop-downs {
	nav {
// 	  text-align: center;
// 	  display: flex;
// 	  justify-content: center;
// 	  margin-top: 1rem;
// 	  margin-bottom: 1rem;
	}
// 	nav a {
// 	  display: block;
// 	  text-decoration: none;
// 	  padding: 0.2rem 2rem;
// 	}
// 	nav a:hover {
// 	  color: white;
// 	  text-decoration: underline;
// 	}
// 	nav ul {
// 	  padding-left: 0;
// 	  list-style: none;
// 	}
// 
// 	nav > ul {
// 	  display: flex;
// 	  justify-content: center;
// 	  flex-wrap: wrap;
// 	}

// 	nav.main-nav {
// 	  text-align: center;
// 	  display: block;
// 	  width: 67%;
// 	  margin-left: auto;
// 	  margin-right: auto;
// 	  margin-top: 1rem;
// 	  margin-bottom: 2.5rem;
// 	}
// 	@media (max-width: 575px) {
// 	  nav.main-nav {
// 		font-size: 0.875rem;
// 	  }
// 	}
// 	@media (max-width: 767px) {
// 	  nav.main-nav {
// 		width: 100%;
// 	  }
// 	}
// 	nav.main-nav a {
// 	  color: #1e5072;
// 	  font-weight: bold;
// 	  text-decoration: none;
// 	  display: block;
// 	  padding: 0.5rem 1rem;
// 	}
// 	nav.main-nav a:hover {
// 	  color: white;
// 	  text-decoration: underline;
// 	}
	nav.main-nav > ul {
	  z-index: 100;
	}
	nav.main-nav > ul > li {
// 	  flex-basis: 0;
// 	  flex-grow: 1;
// 	  margin-left: 6px;
// 	  margin-right: 6px;
	  position: relative;
	//   border-top-right-radius: 0.5rem;
	//   border-top-left-radius: 0.5rem;
	}
	@media (max-width: 575px) {
	  nav.main-nav > ul > li {
		margin-left: 3px;
		margin-right: 3px;
	  }
	}
	nav.main-nav > ul > li:hover {
	//   background-color: rgba(30, 80, 114, 0.75);
	//   box-shadow: 0 0 5px rgba(0, 0, 0, 0.33);
	}
	// nav.main-nav > ul > li:hover > a {
	//   color: white;
	// }
	// nav.main-nav > ul > li > a {
	//   border-bottom: 2px solid #1e5072;
	//   border-top-right-radius: 0.5rem;
	//   border-top-left-radius: 0.5rem;
	// }
	// nav.main-nav li:first-of-type .submenu {
	//   width: 125%;
	//   border-top-right-radius: 0.5rem;
	// }
	// nav.main-nav li:first-of-type .submenu li:first-child,
	// nav.main-nav li:first-of-type .submenu li:first-child a {
	//   border-top-right-radius: 0.5rem;
	// }
	// nav.main-nav li:nth-of-type(2) .submenu {
// 	  width: 150%;
// 	  margin-left: -25%;
// 	//   border-top-right-radius: 0.5rem;
// 	//   border-top-left-radius: 0.5rem;
// 	}
	// nav.main-nav li:nth-of-type(2) .submenu li:first-child,
	// nav.main-nav li:nth-of-type(2) .submenu li:first-child a {
	//   border-top-right-radius: 0.5rem;
	//   border-top-left-radius: 0.5rem;
	// }
	nav.main-nav .submenu {
	  position: absolute;
// 	  width: 100%;
// 	  	  width: 150%;
	  	min-width: 300px;
// 		border-radius: 8px;
	  	background-color: white;
	  	text-align: left;	
	  	z-index: 1000;
	  	margin-left: -10px;
	  	border: 1px solid;
// 	  	border-color: var(--jl-light-teal);
	  	border-color: rgba(139, 174, 170, 0.25);
	}
	nav.main-nav .submenu a {
	  color: var(--jl-teal);
	//   border-bottom: 1px solid silver;
	  padding: 10px;
	}
	@media (max-width: 575px) {
	  nav.main-nav .submenu a {
		font-size: 0.75rem;
	  }
	}
	nav.main-nav .submenu a:hover {
	//   color: white;
	}
	nav.main-nav .submenu li:last-child,
	nav.main-nav .submenu li:last-child a {
	//   border-bottom-right-radius: 0.5rem;
	//   border-bottom-left-radius: 0.5rem;
	//   border-bottom: none;
	}
	nav.main-nav .submenu ul li:hover {
	//   background-color: rgba(30, 80, 114, 0.75);
	}
	nav.main-nav .submenu {
	  display: none;
	  z-index: 200;
	}
	nav.main-nav ul li a:hover + .submenu,
	nav.main-nav ul li a + .submenu:hover {
	  display: block;
	}
}