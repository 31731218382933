#in-the-news p {
  margin-bottom: 3rem;
}
#in-the-news p img {
  width: auto;
  height: 1.5rem;
  margin-right: 0.75rem;
  margin-top: 0.75rem;
  display: block;
  float: left;
}
@media (min-width: 576px) {
  #in-the-news p img {
    width: auto;
    height: 2.25rem;
    margin-top: 0.25rem;
  }
}
#in-the-news p img[alt="The Wall Street Journal"] {
  height: 1.125rem;
}
@media (min-width: 576px) {
  #in-the-news p img[alt="The Wall Street Journal"] {
    height: 2rem;
    margin-top: 0.5rem;
  }
}
#in-the-news p small {
  margin-top: 2px;
}