@use "variables" as *;

#who-we-are {
  width: 100%;
  margin-bottom: 0;

  p img {
    margin-right: 1rem;
  }

  .main {
    margin: 0 auto;
  }
}

#cm-bio-photo {
  width: 130px;
  height: 171px;

  @media (min-width: $small-breakpoint) {
    width: 173px;
    height: 228px;
  }

  @media (min-width: $medium-breakpoint) {
    width: 231px;
    height: 304px;
  }
}

blockquote#neurodiversity-affirming-approach {
  border-left: 3px solid $theme-text-color;
  padding-left: 1.25rem;
  clear: none;
  
  p {
  }
  
  // Add more `margin-top` between the small and medium breakpoints to force it to jump below the picture (without splitting the line)
  @media (min-width: $small-breakpoint) {
    margin-top: 3rem;
  }

  // Add `margin-left` to bring the left-hand border over to the other side of the picture when this quote will be alongside the picture
  @media (min-width: $medium-breakpoint) {
    margin-left: 241px;
	margin-top: 2rem;
  }
  
}