$body-bg-color: #fff;
//$body-bg-color: #eee;
$body-text-color: black;
$body-line-height: 1.5;
$body-font-size: 16px;

// $theme-text-color: #385961;
$theme-text-color: var(--jl-midnight);

$form-field-border-color: #707070;

$small-breakpoint: 576px;
$medium-breakpoint:  768px;

$color-teal: #385961;

:root {
  // Primary colors:
  --jl-midnight: #385961;
  --jl-cornlfower: #557AA1;
  --jl-teal: #7A9F99;
  --jl-light-teal: #8BAEAA;
  --jl-punch: #E09174;
  --jl-latte: #FFF8F2;
  --jl-olive: #828977;  // Not on style guide

  // Secondary colors:
  --jl-flamingo: #FFB2C2;
  --jl-melon: #F4A077;
  --jl-sky: #E2EBF5;
  --jl-field: #EAE7D4;
  --jl-peony: #FDEDE7;
}