.float-left {
  float: left;
}

.float-right {
  float: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.hide {
  display: none;
}

.narrower {

}

.nowrap {
  white-space: nowrap;
}