@charset "UTF-8";
@import url("https://use.typekit.net/jwf3vqe.css");
@import "variables";
@import "layout";
@import "typography";
@import "forms";
@import "util";
@import "nav";
@import "footer";
@import "homepage";
@import "who-we-are";
@import "as_seen_in";
@import "in-the-news";
@import "button";
@import "mailchimp-form";
@import "responsive";

html {
  font-size: $body-font-size;

  @media (max-width: 767px) {
    font-size: $body-font-size - 2px;
  }
}

.featured-in {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  padding-top: 2rem;
  padding-bottom: 3rem;
  z-index: 100;
  position: relative;
  background-color: #adc2d0;

  .container {
    position: relative;
    z-index: 200;

    ul {
      margin-bottom: 0 !important;
    }
  }

}

.featured-in::after {
  border-top-left-radius: 100%;
  border-top-right-radius: 100%;
  bottom: 0;
  background-color: #cde4f8;
  content: '';
  //height: 115%;
  height: 100%;
  //left: -5%;
  left: 0;
  position: absolute;
  //width: 110%;
  width: 100%;
}

.featured-content-grid {
  position: relative;
  overflow: hidden;
  padding-top: 80px;
}

.featured-content-grid::after {
  content: '';
  background-color: #adc2d0;
  position: absolute;
  bottom: 0;
  width: 200%;
  height: 100%;
  border-top-left-radius: 200%;
  border-top-right-radius: 200%;

  @media (min-width: $small-breakpoint) {
    border-top-left-radius: 150%;
    border-top-right-radius: 150%;
  }

  @media (min-width: $medium-breakpoint) {
    border-top-left-radius: 125%;
    border-top-right-radius: 125%;
  }
}

.semi-black-bg {
  color: white !important;
  text-shadow: rgba(0, 0, 0, 0.5) 1px 1px 4px;
  background-color: rgba(0,0,0,0.5);
  border-radius: 0.5rem;
}

.full-height {
  height: 100vh;
}

img.float-left {
  margin: 4px 8px 4px 0;
}