//Sign-up form
@use "variables" as *;
@use "sass:color";

#mc_embed_signup table {
  width: 75%;
  border-collapse: separate;
}

#mc_embed_signup > p {
  margin-bottom: 1rem;
}

.mc-field-group {
}

#mc_embed_signup input {
	margin-top: 16px;
}

.mc-field-group input {
  border: 1px #bbb solid;
  border-radius: 0.25rem;
//   padding: 0.25rem;
  width: 100%;
  	padding: 8px 12px;
	font-size: 90%;
	text-transform: uppercase;
}

.mc-field-group .field-label {
  text-align: right;
}

.mc-field-group .field-label label {
  margin-right: 0.5rem;
}

#mc_embed_signup .button {
//   background-color: $theme-text-color;
//   border-color: scale-color($theme-text-color, $lightness: +20%);
//   border-color: $form-field-border-color;
//   color: white;
//   border-style: solid;
//   border-width: 1px;
//   border-radius: 0.25rem;
//   padding: 0.25rem 1rem;
}

#mc_embed_signup .button:hover {
//   background-color: scale-color($theme-text-color, $lightness: +10%);;
//  border-color: scale-color($theme-text-color, $lightness: -20%);
//   border-color: $form-field-border-color;
}

#mc_embed_signup .button:active,
#mc_embed_signup .button:active:hover,
#mc_embed_signup .button:focus {
//   background-color: scale-color($theme-text-color, $lightness: +20%);
//   border-color: scale-color($theme-text-color, $lightness: -20%);
//   border-color: $form-field-border-color;
}

.asterisk {
  color: #e85c41;
  font-size: 150%;
  position: relative;
  top: 5px;
}

.alert.alert-info {
  color: black;
  border: 2px solid #a95b4d;
  text-align: center;
  font-size: 110%;
  line-height: 1.5;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
}