@use "variables" as *;

@font-face {
  font-family: "Mackinac";
  src: url("./fonts/mackinac/P22Mackinac-Book.woff2") format("woff2");
}

@font-face {
  font-family: "Mackinac";
  src: url("./fonts/mackinac/P22Mackinac-BookItalic.woff2") format("woff2");
  font-style: italic;
}

@font-face {
  font-family: "Mackinac";
  src: url("./fonts/mackinac/P22Mackinac-Bold.woff2") format("woff2");
  font-weight: bold;
}

@font-face {
  font-family: "Mackinac";
  src: url("./fonts/mackinac/P22Mackinac-BoldItalic.woff2") format("woff2");
  font-weight: bold;
  font-style: italic;
}

// @font-face {
//   font-family: "Minerva Modern";
//   src: url("./fonts/minerva-modern/MinervaModern-Regular.woff2") format("woff2");
// }
// 
// @font-face {
//   font-family: "Minerva Modern";
//   src: url("./fonts/minerva-modern/MinervaModern-Bold.woff2") format("woff2");
//   font-style: italic;
// }

@font-face {
  font-family: "Super Mega Fantastic";
  src: url("./fonts/super-mega-fantastic/SupaMegaFantastic-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Super Mega Fantastic";
  src: url("./fonts/super-mega-fantastic/SupaMegaFantasticThin-Regular.woff2") format("woff2");
  font-weight: 300;
}

body {
  font-family: classico-urw, Avenir, sans-serif;
}

h1 {
  font-family: Mackinac, sans-serif;
  font-size: 2.5rem;
  //margin-bottom: 1rem;
  //line-height: 1.1;
  line-height: 1.2;
  color: var(--jl-midnight);
}

#homepage h2,
#homepage h3,
footer h2,
footer h3 {
  font-size: .875rem;
  color: var(--jl-olive);
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  margin-bottom: 1.375rem;
}

#homepage h3,
footer h3 {
  font-size: 1rem;
  margin-top: 1rem;
}

//h2:before {
//  content: '';
//  display: block;
//  height: 8px;
//  position: relative;
//  top: 8px;
//  width: 100%;
//  background-color: rgba($theme-text-color, 0.25);
//}

h3 {
  font-size: 1.375rem;
  margin-top: 2rem;
}

h1, h2, h3, h4, h5, h6, .testimonial {
  //font-family: 'Unna', serif;
  //font-family: 'Recoleta', Palatino, 'Garamond', 'Times New Roman', serif;
}

p {
  line-height: 1.2;
}

a {
  color:  $theme-text-color;

  &:hover {
    color: #000;
  }
}

blockquote {
  border-left: 3px solid $theme-text-color;
  padding-left: 1.25rem;
  clear: both;
}

blockquote p, .blockquote {
    font-size: 1.5rem;
    font-family: Mackinac, sans-serif;
    margin-top: 2rem;
    margin-bottom: 2rem;
}