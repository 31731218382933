@media (max-width: $medium-breakpoint - 1) {
	html {
		font-size: 12px !important;
	}
	
	#site-header {
		display: block;
		text-align: center;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 10px;
	}
	
	#site-header nav > ul {
		justify-content: center;
	}
	
	#site-header.drop-downs nav.main-nav .submenu {
		min-width: auto;
	}
	
	
	#bringing-joy-back {
		background-size: 100%;
		background-position-x: -30px;
		background-position-y: 90%;
		min-height: 620px;
	}
	
	#bringing-joy-back > div {
		padding-top: 10px;
	}
	
	#bringing-joy-back h1,
	#bringing-joy-back > div p {
		text-align: center;
		width: 100%;
	}
	
	#the-academic-experience > div {
		padding: 10px 20px 20px;
	}
	
	#the-academic-experience > div > p > br {
		display: none;
	}
	
	#welcome-to-joyfully-learning div.welcome-content {
		margin-left: auto;
		margin-right: auto;
		padding-left: 40px;
		padding-right: 40px;
	}
	
	.right-bleed {
		max-width: 100%;
		float: none;
		
		img {
			max-width: 150vw;
			height: auto !important;
		}
	}
	
	#how-we-can-help h1 + p {
		padding-left: 20px;
		padding-right: 20px;
	}
	
	#hey-there {
		overflow: hidden;
		
		img {
			max-width: 150vw;
			height: auto !important;
			float: none;
			
		}
	}
	
	#hey-there > div {
		padding-top: 0;
		padding-left: 20px;
		padding-right: 20px;
		margin-top: -70px;
		
		img {
		}
		
		p:nth-of-type(2) {
			text-align: center;
		}
	}
	
	#hey-there > div .hey span {
	    left: 0;
    top: -10px;
    position: relative;
	}
	
	#how-we-can-help {
		margin-top: 0;
		padding-top: 50px;
	}
	
	#how-we-can-help section,
	#how-we-can-help section#self-directed-enrichment {
		padding-left: 20px;
		padding-right: 20px;
	}
	
	#how-we-can-help section h2 {
		width: 170px;
		height: 170px;
		font-size: 20px;
		position: static;
		margin-left: auto;
		margin-right: auto;
	}
	
	#how-we-can-help section#academic-tutoring h2 {
			border-radius: 25px;
	}
	
	#how-we-can-help section#self-directed-enrichment h2 {
		background-size: 170px 170px;
		font-size: 90%;
		width: 170px;
		height: 170px;
		font-size: 16px;
		
		img {
			width: 35px;
			margin-top: 0;
			margin-left: 0;
		}
	}
	
	#how-we-can-help section > div {
		border-radius: 20px;
	}

	
	
	#how-we-can-help section#academic-tutoring div,
	#how-we-can-help section#self-directed-enrichment div,
	#how-we-can-help section#educational-consulting div {
		margin-left: auto;
		margin-right: auto;
		padding: 20px;
	}
	
	#how-we-can-help section#educational-consulting h2 {
		width: 170px;
		height: 170px;
		
		img {
			height: 45px;
			margin-top: 0;
			margin-left: 0;
		}
	}
	
	#we-do-things-differently {
		padding-top: 50px;
	}
	
	#we-do-things-differently > .container {
		padding-left: 40px;
		padding-right: 40px;
	}
	
	#we-do-things-differently-lower {
		background-size: cover;
	}
	
	section.lower-carousel {
		flex-direction: column;
	}
	
	#we-do-things-differently-carousel-secondary .carousel-item img {
		max-width: 90vw;
	}
	
	.carousel-navigation, #carousel-call-to-action {
		text-align: center !important;
	}
	
	#ready-to-learn-with-joy {
		padding-bottom: 40px;
	}
	
	#ready-to-learn-with-joy > div > div {
		row-gap: 40px;
	}
	
	#a-gift-for-you {
		padding: 50px 40px 40px;
		
		& > div > div {
			flex-direction: column-reverse;
		}
	}
	
	#your-child-deserves {
		padding: 50px;
		background-position-x: 85%;
		
		h1, p, a {
			width: auto;
			text-align: center;
		}
	}
	
	ul.featured-in-logos li img {
	max-width: 90vw;
	height: auto;
	}
	
}