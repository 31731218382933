@use "variables" as *;

footer {
  background-image: url('../img/home/JL-Latte-Wave-Sm-C.png');
  background-size: 480px;
  background-position: top center;
  padding: 0;
  background-repeat: repeat-x;
  margin-top: -70px;
  position: relative;
  z-index: 600;

  color: $theme-text-color;
  //
  //&::before {
  //  content: ' ';
  //  position: absolute;
  //  top: -20px;
  //  width: 100px;
  //  height: 100px;
  //  background-image: url('../img/home/jl-medallion.png');
  //  background-repeat: no-repeat;
  //  background-size: 100px 100px;
  //}

  #jl-medallion-container {
    text-align: center;
    position: relative;
    top: -18px;
  }

  h1 {
    font-family: 'Super Mega Fantastic', cursive;
    color: var(--jl-teal);
    font-size: 4rem;
    text-transform: lowercase;
    position: relative;
    z-index: 700;
  }

  h2, h3, #homepage & h3 {
    color: var(--jl-midnight);
    text-transform: uppercase;
  }

  p {
	text-transform: uppercase;
    text-align: center;
    font-size: .75rem;
    background-color: var(--jl-latte);
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  #footer-content-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 30px;
    background-color: var(--jl-latte);
    position: relative;
    z-index: 600;

	// Social media posts
    aside {
//       flex: 3 1 0px;
      text-align: center;

      ul {
        position: relative;
        margin-top: -30px;
      }

      ul > li {
        display: inline-block;
        margin-right: 6px;
      }

      img {
        width: 160px;
        height: 160px;
        box-shadow: 0 0 3px rgba(127, 127, 127, 0.5);
        margin-bottom: 10px;
        object-fit: cover;
      }
      
      img:hover {
      	box-shadow: 0 0 5px rgba(127, 127, 127, 0.75);
      }
    }
  }

  nav {
// 	flex: 1 1 0;
    margin-top: 50px;
    font-size: 0.875rem;

    ul {
      justify-content: space-evenly;
      margin-bottom: 0;

      li a {
        text-decoration: none;
        text-transform: uppercase;
      }
      
      li a:hover {
        text-decoration: underline;
      }
    }
  }

    // All nav lists have NO bullet styling
    ul {
      padding-left: 0;
      list-style: none;
    }
}