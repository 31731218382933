@use "variables" as *;
#as-seen-in {
  p {
    // Extra bottom margin to give the logos breathing room
    margin-bottom: 3rem;
  }

  // Press logos
  p img {
    // Small screens should limit the width of the logos
    width: auto;
    height: 1.5rem;
    margin-right: 0.75rem;
    margin-top: 0.75rem;
    display: block;
    float: left;

    @media (min-width: $small-breakpoint) {
      width: auto;
      height: 2.25rem;
      margin-top: 0.25rem;
    }
  }

  p img[alt="The Wall Street Journal"] {
    height: 1.125rem;

    @media (min-width: $small-breakpoint) {
      height: 2rem;
      margin-top: 0.5rem;
    }
  }
}