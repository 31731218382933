button, .button {
  background-color: var(--jl-melon);
  display: inline-block;
  padding: 14px 65px;
  border-radius: 32px;
  border: none;
  color: white;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  vertical-align: middle;
  font-weight: 500;
}

button:hover, .button:hover, input[type="submit"]:hover {
  color: white;
  text-decoration: underline;
}

button.teal, .button.teal {
  background-color: var(--jl-teal);
}

button.blue, .button.blue {
  background-color: var(--jl-cornlfower);
}

button.white, .button.white {
  background-color: white;
  color: var(--jl-teal);
}

button.white:hover, .button.white:hover {
  color: var(--jl-teal);
}

.carousel-navigation button,
.carousel-navigation .button {
	background: none;
	padding: 0;
	border-radius: 0;
	color: var(--jl-teal);
	font-weight: normal;
}