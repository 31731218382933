@use "variables" as *;

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.container-fluid {
  padding-left: 0;
  padding-right: 0;
}

#homepage {
  color: var(--jl-midnight);
  margin-bottom: 0;
      
  section {
    button, .button {
      font-size: .85rem;
    }
    
    p {
      font-size: 1.0625rem;
    }
    
    p, li {
    }
    
  }
}

#bringing-joy-back {
  min-height: 540px;
  background-image: url('../img/home/hero-image-fade-4-crop.jpg');
  background-repeat: no-repeat;
  background-size: 730px 445px;
  background-position-x: right;
  background-position-y: 60px;
  //background-position: right bottom;
  
  h1 {
    width: 50%;
  }
  
  p {
    width: 42%;
  }
  
  & > div {
    padding: 25px 35px;
    
    p {
      margin-top: 16px;
    }
  }
}

#blue-wave {
    background-image: url('../img/home/blue-wave-20.png');
    background-repeat: repeat-x;
    background-position: top center;
    background-size: 1440px;
    height: 170px;
    margin-top: -180px;
}

#the-academic-experience {
  text-align: center;
  background-color: var(--jl-sky);
  
  h1 {
    // TODO: Why is this different from the header at the top of the page?
    line-height: 1.1;
    margin-bottom: 1.25rem;
  }
  
  & > div {
    padding: 50px 70px 20px;
    
    p { 
      margin-bottom: 22px;
    }
  }
  
  .button:last-of-type {
    margin-top: 24px;
    margin-bottom: 46px;
  }
}

#welcome-to-joyfully-learning {
  // display: flex;
  margin-right: 0;	// Allows clipped image bleeding off right side
  padding-right: 0;
  
  h1 {
    line-height: 1.1;
    margin-bottom: 1.25rem;
  }
  
  & div.right-bleed {
  	width: 480px;
    float: right;
    margin-top: -83px;
    overflow: hidden;
  }
  
  & div.welcome-content {
    padding-top: 100px;
    padding-right: 0;
    padding-left: 80px;
  }
  
  img {
//     float: right;
//     margin-top: -83px;
//     margin-right: -151px;
  }
  
  @media (max-width: 1276px) {
  	& {
//   		margin-left: calc((100vw - 980px) / 2)
  	}
  	
  	& div.welcome-content {
	max-width: 470px;
    margin-right: calc(((100vw - 980px) / 2) + 480px);	// The margin extends all the way to the rght (ast the floated element)
    // This calculation is: half of the viewport width minus 980px for the container. Then, an additional 480 px for the cropped image.
    padding-left: 0;  	}
  }
  
  @media (min-width: 1277px) {
    & {
      margin-right: auto;
      padding-right: inherit;
    }
    
    & div.right-bleed {
    	width: 622px;
    	overflow: unset;
    	margin-right: -151px;
    }
  }
}

#hey-there {
  padding-top: 28px;
  padding-bottom: 50px;
  
  //min-height: 750px;
  
  & > img {
    width: 628px;
    height: auto;
    float: left;
    margin-left: -170px;
    margin-right: 30px;
  }
  
  & > div {
    padding-right: 60px;
    padding-top: 40px;
    
    .hey {
      span {
        font-family: 'Super Mega Fantastic', cursive;
        font-size: 58px;
        color: var(--jl-light-teal);
        display: inline-block;
        position: relative;
        left: -54px;
        top: 21px;
        transform: rotate(-8deg);
      }
    
      color: var(--jl-punch);
    }
    
    h2 {
      margin-bottom: 14px;
      font-weight: normal;
      color: var(--jl-midnight);
    }
    
    p {
      margin-top: 8px;
    }
  }
}

#how-we-can-help {
  margin-top: -152px;
  padding-top: 95px;
  padding-bottom: 150px;
  background-image: url('../img/home/paper-texture-4.jpg');
  background-repeat: repeat;
  background-size: 980px;
  z-index: 500;
  
  p {
    margin-bottom: 10px;
  }
  
  h1  {
    margin: 40px auto 25px;
    color: var(--jl-melon);
    text-align: center;
  }
  
  h1 + p {
    text-align: center;
    padding-left: 150px;
    padding-right: 150px;
  }
  
  h2 {
      font-family: Mackinac, serif;
      font-weight: normal;
      text-transform: none;
      text-align: center;
      letter-spacing: 0;
      color: var(--jl-latte);
      font-size: 35px;
      position: absolute;
      width: 245px;
      height: 245px;
      z-index: 600;
      padding: 30px 20px;
  }
  
  section {
    position: relative;
    padding-left: 90px;
    padding-right: 90px;
    margin: 50px auto 75px;
    
    ul {
      padding-left: 1rem;
      margin-top: 21px;
      margin-bottom: 20px;
      line-height: 1.3;
    }
  
    h3 {
      text-transform: uppercase;
      margin-bottom: 4px;
      color: var(--jl-midnight);
      font-weight: 500;
    }
  
    h3 + p {
      margin-top: 4px;
    }
  
    a {
      color: var(--jl-punch);
      font-size: 0.75rem;
      letter-spacing: 2px;
      font-weight: bold;
      text-transform: uppercase;
      text-underline-offset: 8px;
    }
    
    a:hover {
    	color: var(--jl-midnight);
    }
    
    & > div {
      background-color: white;
      position: relative;
      border-radius: 85px;
      z-index: 500;
    }
  
    &#academic-tutoring {
		h2 {
		  left: 70px;
		  top: 88px;
		  border-radius: 50px;
		  background-color: var(--jl-cornlfower);
		  transform: rotate(-6deg);
		}
		
    	div {
		  padding: 40px 45px 45px 200px;
		  margin-left: 100px;
		}
	}
  
    &#self-directed-enrichment {
	    padding-left: 135px;
	    
        h2 {
		  background-image: url('../img/home/small-orange-medallion.png');
		  background-size: 330px 330px;
		  background-repeat: no-repeat;
		  transform: rotate(1deg);
		  width: 330px;
		  height: 330px;
		  right: 36px;
		  top: 48px;
  
		  img {
			display: inline-block;
			width: 50px;
			height: auto;
			margin-top: 24px;
			margin-left: 18px;
		  }
  
		  span {
			display: inline-block;
			margin-top: 8px;
			transform: rotate(5deg);
		  }
		}
    
		div {
		  padding: 55px 180px 45px 82px;
		  margin-right: 85px;
		}
    }
  
    &#educational-consulting {
		h2 {
		  left: 68px;
		  top: 75px;
		  width: 280px;
		  height: 280px;
		  border-radius: 50%;
		  background-color: var(--jl-teal);
  
		  img {
			display: inline-block;
			width: auto;
			height: 68px;
			margin-top: 14px;
		  }
  
		  span {
			display: inline-block;
			margin-top: 12px;
			transform: rotate(-6deg);
		  }
		}
    
    	div {
	      padding: 54px 50px 67px 168px;
    	  margin-left: 144px;
	    }
	}
  }
}

.testimonial {
  text-align: center;
  // position: relative;
  // z-index: 600;
  
  h1 {
    margin-top: 40px;
    margin-bottom: 24px;
  }
  
  h1 + p {
    font-size: 1.125rem;
    margin-bottom: 1.5rem;
    max-width: 760px;
    margin-left: auto;
    margin-right: auto;
  }
  
  p.testimonial-attribution {
    text-transform: uppercase;
    font-size: .875rem;
    font-weight: 500;
  }
}

#beige-wave {
 background-image: url('../img/home/beige-wave-3.png');
 background-repeat: repeat-x;
 background-position: top center;
 background-size: 970px;
 height: 60px;
 margin-top: -72px;
 
}

// "So lucky to have found you..."
#testimonial-1 {
  padding-top: 60px;
  padding-bottom: 60px;
  // background-size: 100%;
  // position: relative;
  // z-index: 600;
  background-color: var(--jl-field);

}

#testimonial-2 {
  padding-top: 60px;
  padding-bottom: 60px;
}

#as-seen-in {
  text-align: center;
  padding-top: 80px;
  padding-bottom: 64px; // Accounts for 16px bottom padding on <li> items
  background-color: var(--jl-latte);
  
  h1 {
    font-size: 1.5rem;
  }
  
  ul {
    margin-bottom: 0;
  }
}

#we-do-things-differently {
  padding: 100px 0 0;
  text-align: center;
  
  h1 {
    color: var(--jl-punch);
  }
  
  h1 + p {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
  
  #we-do-things-differently-lower {
//     background-image: url('../img/home/light-waves-overlapped.png');
//     background-image: url('../img/home/JL-Peony-Wave-2.png');
    background-image: url('../img/home/JL-Peony-Wave-1.png');
// 	background-size: 1100px;
	background-size: 100%;
	background-repeat: no-repeat;
	background-position: top center;
	}
  
  section.lower-carousel {
    display: flex;
    align-items: center;
    gap: 10px;

	.carousel {
		text-align: left;
		margin-left: auto;
		margin-right: auto;
	}
	
	#we-do-things-differently-carousel-primary-parent {
		flex-grow: 2;
	}
	
	#we-do-things-differently-carousel-secondary {
		flex-grow: 1;
	}
  
    h1 {
      font-family: 'Super Mega Fantastic', cursive;
      color: var(--jl-olive);
      text-align: left;
      margin-bottom: 0.5rem;
      font-size: 2.8125rem;
      padding: 0.5rem 0 0 0.5rem;
    }
  
    p.carousel-slide-text {
      text-align: left;
      min-height: 170px;
      margin-top: 0;
      margin-bottom: 0;
    }
  
    .carousel-navigation {
      text-align: left;
      
      button {
      	font-size: 24px;
      }
      
      button:first-of-type {
      	margin-right: 10px;
      }
      
      button:hover {
      	text-decoration: none;
      }
    }
    
    a.button.teal {
    	margin-top: 16px;
    }
  }
}

#ready-to-learn-with-joy {
  background-image: url('../img/home/paper-texture-4.jpg');
  background-repeat: repeat;
  text-align: center;
    
  h1 {
    line-height: 1;
    margin-top: 24px;
    margin-bottom: 20px;
  }
  
  h1 span.joy {
    font-family: 'Super Mega Fantastic', cursive;
    font-size: 4rem;
    color: var(--jl-punch);
  }
  
  p {
    margin-top: 1rem;
    margin-bottom: 1.75rem;
    max-width: 85%;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.6;
  }
}

#a-gift-for-you {
  padding: 110px 0;
  background-color: var(--jl-latte);
  text-align: center;
  
  h1 {
	color: var(--jl-punch);
  }
  
  h2 {
  	color: var(--jl-teal);
  	font-weight: 500;
  }  
  
  h1 span {
    font-family: 'Super Mega Fantastic', cursive;
    font-size: 2.5rem;
  }
  
  p {
  	line-height: 1.5;
  	margin-bottom: 24px;
  }
  
  input[type="text"],
  input[type="email"] {
  	display: block;
  	width: 100%;
  	text-align: center;
  }
  
  button,
  .button {
  	text-align: center;
  	margin-left: auto;
  	margin-right: auto;
  }
}

#your-child-deserves {
  background-image: url('../img/home/kids-reading.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  padding: 130px 60px 100px;
  min-height: 600px;
  position: relative; // For pseudo-content tint
  
  h1, p {
    color: white;
    width: 490px;
  }
  
  p {
    margin-top: 20px;
    margin-bottom: 30px;
  }
  
  h1, p, a {
    position: relative;
    z-index: 600;
  }
  
  // Pseudo-content tint
  &::before {
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba($color-teal, 0.25);
    z-index: 500;
  }
  
  
}


section, div {
  // border: 1px dotted red;
}

.kinetic-icon:hover {
  animation: wiggle 2s linear infinite;
}

/* Keyframes */
@keyframes wiggle {
  0%, 7% {
    transform: rotateZ(0);
  }
  15% {
    transform: rotateZ(-15deg);
  }
  20% {
    transform: rotateZ(10deg);
  }
  25% {
    transform: rotateZ(-10deg);
  }
  30% {
    transform: rotateZ(6deg);
  }
  35% {
    transform: rotateZ(-4deg);
  }
  40%, 100% {
    transform: rotateZ(0);
  }
}

/* Press Logos */
section.featured-in {
  margin: 2rem auto;

  h3 {
    font-size: 1.25rem;
  }
}

ul.featured-in-logos {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding-left: 0;
  list-style: none;
  align-items: center;

  li {
    padding: 1rem 1rem;
    display: block;

    img {
      height: 30px;
      width: auto;
      filter: opacity(0.67);
      transition: filter .2s;
    }

    img:hover {
      filter: opacity(1.00);
    }
  }
}

