@use "variables" as *;

body {
  margin: 0;
  font-size: 1rem;
  line-height: $body-line-height;
  background-color: $body-bg-color;
  color: $body-text-color;
}

#site-header {
  margin: 20px auto 30px;
}

main, .main {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 100px;
  //width: 90%;
  //
  //@media (min-width: $medium-breakpoint) {
  //  width: 75%;
  //}
}

.container-full {
  width: 100%;
}

.container {
  max-width: 960px;
  //padding: 0 45px;
  margin-left: auto;
  margin-right: auto;
  //background-color: #fff;
}

.container, .container-full {
  clear: both;
}

p {
  margin-top: 16px;
  margin-bottom: 16px;
}

// If we're on a page with an <h1> followed immediately by a paragraph (ie, most single-topic sub-pages), add a blue bar and some spacing
// Also add this above each <h2> in a <section>
main h1 + p,
section h2,
main h1 + div {
  //padding-top: 1.75rem;
}

//section {
//  margin-bottom: 3rem;
//  position: relative;
//
//  //& > p,
//  & > h3,
//  & > ul {
//    margin-left: 2rem;
//  }
//
//  & > h2 {
//    margin-left: 0;
//  }
//
//  // Back to Top link: hidden by default
//  & > h2 + small {
//    display: none;
//  }
//
//  // Back to Top link: visible when this item selected via URL hash
//  & > h2:target + small {
//    display: block;
//    margin: 0.25rem 0;
//
//    @media (min-width: $small-breakpoint) {
//      position: absolute;
//      top: 1rem;
//      right: 0;
//      padding-top: 1.5rem;
//      margin: 0;
//    }
//  }

  // Back to Top link: visible when this item hovered over
  //& > h2:hover + small {
  //  display: block;
  //  position: absolute;
  //  top: 1rem;
  //  right: 0;
  //}
//}

//h2:target:before {
//  content: ' 👉';
//  position: absolute;
//  left: -2rem;
//}

.row {
  clear: both;
}

.column {
  float: none;
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  // Columns will float only on devices ≥ 576px wide
  @media (min-width: $small-breakpoint) {
    float: left;
  }
}

.half {
  width: 50%;
}

.third {
  @media (min-width: $small-breakpoint) {
    width: 33.3333%;
  }
}

.two-thirds {
  @media (min-width: $small-breakpoint) {
    width: 66.6667%;
  }
}

.three-quarters {
  width: 75%;
}