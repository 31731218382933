input[type="text"],
input[type="email"] {
	padding-top: 8px;
	padding-bottom: 8px;
	font-size: 90%;
	text-transform: uppercase;
}

input, button, .button {
outline-color: var(--jl-light-teal);
}

// Override of default Bootstrap behavior
.form-control:focus {
	border-color: var(--jl-light-teal);
	box-shadow: 0 0 0 2px rgba(55, 68, 67, .25);
}